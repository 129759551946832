import Button from '@components/buttons/Button';
import CheckboxMvp from '@components/inputs/CheckboxMvp';
import Input from '@components/inputs/Input';
import { CancelIcon, CheckInCircleIcon, ShowPasswordIcon, ShowPasswordOffIcon } from '@components/svg';
import { useLocale } from '@i18n';
import { FormInputState, State } from '@models';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ERoutes, getPathByRouteName } from '../../routes';
import S from './styles/CreatePassword.styl';

type TCheckboxName = 'terms' | 'newsletter';

enum IconState {
	INITIAL,
	VALID,
	ERROR,
}

interface IPasswordValidation {
	validationType: string;
	isValid: boolean;
	iconState: IconState;
	regex: string;
}

export interface INewPasswordForm {
	password: string;
	confirmPassword: string;
	oldPassword: string;
}
export interface IInputs {
	password: string;
	confirmPassword: string;
	oldPassword: string;
	terms: boolean;
	newsletter: boolean;
}

interface ICreatePassword {
	handleFormSubmit: (inputs: IInputs, e?: React.FormEvent<HTMLFormElement>) => void;
	setFormCallback?: React.Dispatch<React.SetStateAction<INewPasswordForm>>;
	setIsValidCallback?: React.Dispatch<React.SetStateAction<boolean>>;
	terms?: boolean;
	newsletter?: boolean;
}

export default function CreatePassword({ handleFormSubmit, setFormCallback, setIsValidCallback, terms, newsletter }: ICreatePassword) {
	const T = useLocale();
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
	const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
	const [formValid, setFormValid] = useState<boolean>(false);
	const [inputs, setInputs] = useState<IInputs>({
		password: '',
		confirmPassword: '',
		oldPassword: '',
		terms: !terms,
		newsletter: false,
	});
	const [passwordValidationType, setPasswordValidationType] = useState<IPasswordValidation[]>(
		[
			{
				validationType: 'hasMinLengthValid',
				isValid: false,
				iconState: IconState.INITIAL,
				regex: '^[^\\n ]{8,}$',
			},
			{
				validationType: 'hasCapitalAndLowerValid',
				isValid: false,
				iconState: IconState.INITIAL,
				regex: '^(?=.*[a-z])(?=.*[A-Z])[^\\s]{0,}$',
			},
			{
				validationType: 'hasNumber',
				isValid: false,
				iconState: IconState.INITIAL,
				regex: '.*[0-9].*',
			},
		],
	);

	useEffect(() => {
		isFormValid();
	}, [inputs]);

	const handleInputChange = (event: any, inputState: FormInputState) => {
		const { name, value } = event.target;
		if (name === 'password') {
			updatePasswordValidationType(value);
		}
		setInputs({ ...inputs, [name]: value });
		if (setFormCallback) {
			setFormCallback({ ...inputs, [name]: value });
		}
	};

	const updatePasswordValidationType = (value: string) => {
		const newPasswordValidationType = cloneDeep(passwordValidationType);
		passwordValidationType.forEach((type, index) => {
			const regex = new RegExp(type.regex);
			const isValid = regex.test(value);
			newPasswordValidationType[index].isValid = isValid;
			newPasswordValidationType[index].iconState = isValid ? IconState.VALID : IconState.ERROR;
		});
		setPasswordValidationType(newPasswordValidationType);
	};

	const getIcon = (iconState: IconState) => {
		if (iconState === IconState.ERROR) {
			return <CancelIcon/>;
		} else if (iconState === IconState.VALID) {
			return <CheckInCircleIcon color={S.success}/>;
		}
		return <CancelIcon color={S.darkBlueGray}/>;
	};

	const getValidationIcon = (validationType: string) => {
		let icon = getIcon(IconState.INITIAL);
		passwordValidationType.forEach((type) => {
			if (validationType === type.validationType) {
				icon = getIcon(type.iconState);
			}
		});
		return icon;
	};

	const isFormValid = () => {
		const isValid = !passwordValidationType.some((type) => {
			return !type.isValid;
		}) && inputs.password === inputs.confirmPassword && inputs.terms;
		setFormValid(isValid);
		if (setIsValidCallback) {
			setIsValidCallback(isValid);
		}
	};

	const handleCheckboxChange = (name: string) => (isChecked: boolean) => {
		setInputs({ ...inputs, [name]: isChecked });
	};

	const getCheckboxState = (name: TCheckboxName) => {
		return inputs[name] ? State.Selected : State.Enabled;
	};

	const onShowPasswordClick = (event: any) => {
		if (event.currentTarget.id === 'password') {
			setShowPassword((prevState) => !prevState);
		}
		if (event.currentTarget.id === 'confirmPassword') {
			setShowConfirmPassword((prevState) => !prevState);
		}
		if (event.currentTarget.id === 'oldPassword') {
			setShowOldPassword((prevState) => !prevState);
		}
	};

	const renderTitle = () => (
		<>
			<h1 className={S.createPasswordContentTitle}>
				{T.LoginCreatePasswordNewPasswordTitle}
			</h1>
			<p className={S.createPasswordContentDescription} dangerouslySetInnerHTML={{ __html: T.LoginCreatePasswordNewPasswordText }} />
		</>
	);

	return (
		<div className={S.createPasswordContent}>
			{!setFormCallback && renderTitle()}
			<form onSubmit={(e) => handleFormSubmit(inputs, e)}>
				{!!setFormCallback &&
					(
						<Input
							className={'admin-client'}
							autoComplete={'old-password'}
							onChange={(e, inputState) => handleInputChange(e, inputState)}
							labelText={T.MyAccountCurrentPassword}
							placeHolder={T.LoginCreatePasswordPasswordPlaceholder}
							inputType={showOldPassword ? 'text' : 'password'}
							name="oldPassword"
							inputAppend={showOldPassword ? <ShowPasswordOffIcon/> : <ShowPasswordIcon/>}
							inputAppendClick={(e) => onShowPasswordClick(e)}
							inputAppendElementId={'oldPassword'}
							validationRegex={['^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[^\\s]{8,}$']}
						/>
					)
				}

				<Input
					className={'admin-client'}
					autoComplete={'new-password'}
					onChange={(e, inputState) => handleInputChange(e, inputState)}
					labelText={!!setFormCallback ? T.MyAccountNewPassword : T.LoginCreatePasswordPasswordLabel}
					placeHolder={T.LoginCreatePasswordPasswordPlaceholder}
					inputType={showPassword ? 'text' : 'password'}
					name="password"
					inputAppend={showPassword ? <ShowPasswordOffIcon/> : <ShowPasswordIcon/>}
					inputAppendClick={(e) => onShowPasswordClick(e)}
					inputAppendElementId={'password'}
					validationRegex={['^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[^\\s]{8,}$']}
				/>

				<Input
					autoComplete={'new-password'}
					className={'admin-client'}
					onChange={(
						e, inputState) => handleInputChange(e, inputState)
					}
					labelText={T.LoginCreatePasswordConfirmPasswordLabel}
					placeHolder={T.LoginCreatePasswordConfirmPasswordPlaceholder}
					inputType={showConfirmPassword ? 'text' : 'password'}
					name="confirmPassword"
					inputAppend={showConfirmPassword ? <ShowPasswordOffIcon/> : <ShowPasswordIcon/>}
					inputAppendClick={onShowPasswordClick}
					inputAppendElementId={'confirmPassword'}
					valid={inputs.password ===  inputs.confirmPassword}
				/>

				<div className={S.createPasswordContentPasswordValidations}>
					<p>{T.LoginCreatePasswordCriteriaText}</p>
					<ul className={S.createPasswordContentPasswordValidationsList}>
						<li>
						<span className={S.createPasswordContentPasswordValidationsIcon}>
							{getValidationIcon('hasMinLengthValid')}
						</span>
							<span>
							{T.LoginCreatePasswordCriteria1Text}
						</span>
						</li>
						<li>
						<span className={S.createPasswordContentPasswordValidationsIcon}>
							{getValidationIcon('hasCapitalAndLowerValid')}
						</span>
							<span>
							{T.LoginCreatePasswordCriteria2Text}
						</span>
						</li>
						<li>
						<span className={S.createPasswordContentPasswordValidationsIcon}>
							{getValidationIcon('hasNumber')}
						</span>
							<span>
							{T.LoginCreatePasswordCriteria3Text}
						</span>
						</li>
					</ul>
				</div>

                {terms && (
                    // <div
                    //     style={{

                    //     }}
                    // >
                    //     <CheckboxMvp
                    //         key="terms"
                    //         title={T.LoginCreatePasswordCheckboxTermsText}
                    //         state={getCheckboxState('terms')}
                    //         onChange={handleCheckboxChange('terms' as TCheckboxName)}
                    //         linkLabel={T.LoginCreatePasswordCheckboxTermsTextLink}
                    //         linkUrl={getPathByRouteName(ERoutes.Terms, T.locale)}
                    //         linkTarget={'_self'}
                    //     />
                    //     <span>,</span>
                    //     <a
                    //         href="https://norbec.com/fr/politique-de-confidentialite/"
                    //         target="_blank"
                    //         rel="noopener noreferrer"
                    //         className="mvp-link"
                    //     >
                    //         {T.MenuLinkLabelPrivacyPolicy}
                    //     </a>
                    //     <span>,</span>
                    //     <a
                    //         href="https://norbec.com/personal-information-and-data-governance-policy-norbec-group-inc/"
                    //         target="_blank"
                    //         rel="noopener noreferrer"
                    //         className="mvp-link"
                    //     >
                    //         {T.MenuLinkLabelGovernancePolicy}
                    //     </a>
                    // </div>
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            marginBottom: '1rem', // Ajustez si besoin
                        }}
                    >
                        {/* La case à cocher */}
                        <input
                            type="checkbox"
                            checked={inputs.terms}
                            onChange={(e) => handleCheckboxChange('terms')(e.target.checked)}
                            style={{
                                // Dimensions (ajustez selon la taille voulue)
                                width: '18px',
                                height: '18px',
                                padding: '0.45rem 0.45rem',
                                cursor: 'pointer',
                                marginRight: '8px',

                                // On désactive l'apparence par défaut du navigateur
                                appearance: 'none',
                                WebkitAppearance: 'none',
                                outline: 'none',

                                // Quand NON coché : bordure grise, fond blanc
                                // Quand coché : fond bleu, plus de bordure
                                border: inputs.terms ? '2px solid rgb(0, 149, 200)' : '2px solid rgb(159, 187, 196)',
                                borderRadius: '2px',
                                backgroundColor: inputs.terms ? '#0095C8' : '#fff',

                                // Icône de check (blanc) encodée en base64, affichée au centre si coché
                                backgroundImage: inputs.terms
                                    ? 'url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij4KICA8cGF0aCBkPSJNMjAuMjkyIDUuMjkxYTEgMSAwIDAgMSAwIDEuNDE0bC05Ljk5OSA5Ljk5OWExIDEgMCAwIDEtMS40MTQgMGwtNS00Ljk5OWExIDEgMCAwIDEgMS40MTQtMS40MTRMOS44OCAxNC4xNzFsOS4yOTItOS4yOTJhMSAxIDAgMCAxIDEuNDE0IDB6IiAvPgo8L3N2Zz4=")'
                                    : 'none',
                                backgroundSize: '20px 20px', // Ajustez pour la taille du check
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}

                        />

                        {/* Le texte + liens */}
                        <span style={{ fontSize: '14px', color: '#333' }}>
                            J’accepte les{' '}
                            <a
                                href="/conditions-utilisation" // ou getPathByRouteName(ERoutes.Terms, T.locale)
                                target="_self"
                                className="mvp-link"
                            >
                                Conditions d’utilisation
                            </a>
                            ,{' '}
                            <a
                                href="https://norbec.com/fr/politique-de-confidentialite/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="mvp-link"
                            >
                                Politique de confidentialité
                            </a>
                            ,{' '}
                            <a
                                href="https://norbec.com/personal-information-and-data-governance-policy-norbec-group-inc/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="mvp-link"
                            >
                                Politique encadrant la gouvernance des renseignements personnels
                            </a>
                        </span>
                    </div>
                )}


				{newsletter && (
					<CheckboxMvp
						key="newsletter"
						title={T.LoginCreatePasswordCheckboxNewsletterText}
						state={getCheckboxState('newsletter')}
						onChange={handleCheckboxChange('newsletter' as TCheckboxName)}
					/>
				)}
				{!setFormCallback && (
					<Button
						typeSubmit
						disabled={!formValid}
					>
						{T.LoginCreatePasswordButtonText}
					</Button>
				)}
			</form>
		</div>
	);
}
